import koboLogo from "../asset/logo/logo_brown_V1.svg";
import hamburgerMenu from "../asset/menu/hamburger-menu.svg";
import { Link, useLocation } from "react-router-dom";

const Topbar = ({
  showHamburger,
  hamburgerClicked,
  showNavigationLinks,
  childrenEnd,
  customCallback,
  color,
}) => {
  // get current route
  const location = useLocation();

  const navigationLinks = [
    { label: "Shop", href: "/shop" },
    { label: "Workshop", href: "/workshop" },
  ];

  const handleRefreshPage = () => {
    window.location.reload();
  };

  const backgroundColorClass = color === "zinc" ? "bg-zinc-100" : "bg-white";

  return (
    <div
      className={`touch-none flex flex-row justify-between items-center p-[2rem] pb-[0.4rem] w-full z-10 md:pt-5 ${backgroundColorClass}`}
    >
      {showHamburger && (
        <div className="flex-1">
          <img
            alt="hamburger-menu"
            onClick={hamburgerClicked}
            src={hamburgerMenu}
            className="w-[3.2rem] cursor-pointer"
          />
        </div>
      )}
      <div className="flex-1">
        <img
          alt="logo"
          onClick={handleRefreshPage}
          src={koboLogo}
          className="w-[11.5rem] h-50 cursor-pointer m-auto min-[901px]:w-154 min-[901px]:m-0"
        />
      </div>
      {showNavigationLinks && (
        <div className="flex flex-row items-center justify-center flex-1">
          <div className="flex flex-row items-center justify-center gap-69 translate-x-9">
            {navigationLinks.map((link, index) => (
              <Link
                key={index}
                to={link.href}
                onClick={() => customCallback?.()}
                className={`font-primary uppercase text-kobo-16 text-black underline-offset-4 cursor-pointer ${
                  location.pathname === link.href ? "underline" : ""
                }`}
              >
                {link.label}
              </Link>
            ))}
          </div>
        </div>
      )}
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-center justify-end gap-3">
          {childrenEnd}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
